<template>
    <div>
        <router-view/>
        <base-button-up></base-button-up>
    </div>
</template>

<script>
import Cookies from 'js-cookie'
import Store from '@/store'

export default {
    name: 'LoginPage',
    async beforeRouteEnter(to, from, next) {
        if (Cookies.get('sbg-in')) {
            Store.commit('application/load', false)
            next('/lk')
        } else {
            next()
        }
    }
}
</script>